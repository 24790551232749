

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddUserProfileAddress from "@/components/modals/forms/AddUserProfileAddress.vue";
import EditUserProfileKmp from "@/components/modals/forms/EditUserProfileKmp.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, {business_types} from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import JwtService from "@/core/services/JwtService";
import { hideModal } from "@/core/helpers/dom";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import {  address_title_types } from "@/core/data/genericData";
export default defineComponent({

  name: "address-listing",
   directives: { mask },
  props: {    
    id: { required: true },
    data: {
        type: Object
    },
  },
  components: {
    ExportCustomerModal,
    AddUserProfileAddress, 
  },
  methods: {
    onChange() {
      if (this.formData['pincode'] == "") {
        this.formData['city'] = "";
        this.formData['state'] = "";
        this.formData['country'] = "";
      }
    },
  }, 
  
  setup(props) {
    var formData = ref({})
    var ids = ref(props.id);
    const count = ref(0)
    const store = useStore();
    const checkedBranch = ref([]);  
    const loadingData = ref<boolean>(true);  
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const editAddressModalRef = ref<null | HTMLElement>(null);
    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const country_value = ref()
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const address_title_type = address_title_types;
    interface IBranches {
      contact_address_id,
      address_title_type,
      address_line_1,
      address_line_2
      pincode,
      city_name,
      state_name,
     
    }

    const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
               formData.value['city'] = data[i].city_name
              formData.value['state'] = data[i].state_name
              formData.value['country'] = data[i].country_name
              formData.value['city_id'] = data[i].city_id
              formData.value['state_id'] = data[i].state_id
              formData.value['pincode_id'] = data[i].pincode_id
              formData.value['country_id'] = data[i].country_id


              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value['city'] = pin_details.city_name
      formData.value['state'] = pin_details.state_name
      formData.value['country'] = pin_details.country_name
      
      city_value.value = pin_details.city_id
      pin_value.value =  pin_details.pincode_id
      state_value.value = pin_details.state_id
      country_value.value = pin_details.country_id
    };

     const setAddressData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        "contact_address_id" : data.id,
        "contact_id" : Number(props.id),
        "address_title_type_id": data.address_title_select,
        "address_line_1": data.address_line_1,
        "address_line_2": data.address_line_2,
        "pincode_id": pin_value.value || data.pincode_id ,
         "user_id": user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_UPDATE_ADDRESSES, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              hideModal(editAddressModalRef.value);
              Swal.fire({
                text: "Address has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({name : "address-listing"});
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const setFormData = async (data) => {    
      data = data['contact_address_id'];
        try {

       var values = { 
            "contact_address_id" : data,
            "contact_id" : props.id,
            }
            console.log('values')
        console.log(values)
        await store.dispatch(Actions.CUST_LIST_ADDRESSES, values).then(({ data }) => {    
          city_value.value = data.city_id
          pin_value.value = data.pincode_id
          state_value.value = data.state_id
          country_value.value = data.country_id
          
          formData.value = {
            id: data.contact_address_id,
            address_title_select: data.address_title_type,
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            pincode: data.pincode,
            pincode_id: data.pincode_id,
            city: data.city_name,
            state: data.state_name,
            country: 'India',
            
            };
            console.log('formData.value')
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setAddressData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

     const rules = ref({
      address_title_select: [
        {
          required: true,
          message: "Address Title is required",
          trigger: "change",
        },
      ],
      address_line_1: [
        {
          required: true,
          message: "Address Line 1 is required",
          trigger: "change",
        },
      ],
      pincode: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "change",
        },
      ],
    });

    var paginationData = ref({})
    
    paginationData.value = {      
          start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,
        mmaxPageg : 6,
    }

    const refreshData =() => {      
      getAddressList(props.id)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;
      getAddressList(props.id)
    }
    
    var tableData = ref<Array<IBranches>>([]);
    
    const getAddressList = async (data) => {
      
      loadingData.value = true;
      var com_id = 0
      if(data._value){
        com_id = data._value
      }else{
        com_id = data 
      }
      
      try {

        var values = { 
           "contact_address_id" : 0,
           "contact_id" : props.id
           }
        await store.dispatch(Actions.CUST_LIST_ADDRESSES, values).then(({ data }) => {

        tableData.value = ([]);
        
        var resultsM = ref<Array<IBranches>>([])
        

        for (let j = 0; j < data.length; j++) {

          

          resultsM.value = Array({

            contact_address_id : data[j].contact_address_id,
            address_title_type : data[j].address_title_type,
            address_line_1 : data[j].address_line_1,
            pincode : data[j].pincode,
            city_name : data[j].city_name,
            state_name : data[j].state_name,
            address_line_2 : data[j].address_line_2,
            
          })

          tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
        }

        loadingData.value = false;

      })
      .catch(({ response }) => {
        
        tableData.value = [];
        loadingData.value = false; 
      });
        
      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<IBranches>>([]);                      

      onMounted( async () => {
        await getAddressList(props.id)
        await setFormData(props.data);
        // setCurrentPageBreadcrumbs("KMP's",["company"]);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);                
      });  

      const deleteFewCustomers = () => {
        checkedBranch.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedBranch.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].contact_address_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
      const searchItems = () => {
        tableData.value.splice(0, tableData.value.length, ...initCustomers.value);        
        if (search.value !== "") {
          let results: Array<IBranches> = [];          
          for (let j = 0; j < tableData.value.length; j++) {
            if (searchingFunc(tableData.value[j], search.value)) {              
              results.push(tableData.value[j]);
            }
          }
          tableData.value.splice(0, tableData.value.length, ...results);
        }
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          

    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedBranch,
      deleteFewCustomers,
      count,      
      getAddressList,       
      paginationData,
      changePageChange,
      refreshData,      
      loadingData,
      ids,
      editAddressModalRef,
      pin,
      getPincodeData,
      ClickPincodeData,
      formData,
      formRef,
      submit,
      setFormData,
      address_title_type,
      rules,
    };  

  }  

});


