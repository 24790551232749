
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import  { user_gst_type} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { gst_type, gst_return_frequency } from "@/core/data/genericData";
import moment from "moment";

export default defineComponent({
  name: "edit-branch-modal",
  directives: { mask },
  props: {
    data: {
        type: Object
    }
  },
  components: {},
  methods :{
     onClearPan() {
      if (this.formData1['pan_search'] == "" || this.formData1['pan_search'] !== "10") {
        this.formData1['individual_pan'] = "";
      }
    },
  },
  
  setup(props) {
    const user_gst_type_data = user_gst_type;
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addContactModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const loadingPan = ref<boolean>(false);
    const router = useRouter();
    const type_list_data = gst_type;
    const freq_list_data = gst_return_frequency;

    const formData = ref({
      pan_search: ""
    });
    
     const getPanData = async () => {
      loadingPan.value = true;

      const db_data = { pan: formData1.value['pan_search'] };

      await store
        .dispatch(Actions.CUST_GET_USER_PROFILE_PAN_SEARCH, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.holder_type == 'individual') {
            formData1.value['individual_pan'] = data["pan"];
            // formData1.value['city'] = data["holder_type"];
            // formData1.value['state'] = data["holder_name"];
            

            loadingPan.value = false;
          } else {
            formData1.value['individual_pan'] = "";
            

            Swal.fire({
              text: "Sorry, It's Invalid PAN!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPan.value = false;
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            text: "Sorry, It's Invalid Pan!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          loadingPan.value = false;
        });
    };

    const onMobileEnter = () => {
      debugger;
      if (
        /^[789]\d{9}$/.test(formData.value["business_mobile"]) &&
        !formData.value["mobile_list"].includes(
          formData.value["business_mobile"]
        )
      ) {
        formData.value["mobile_list"].push(formData.value["business_mobile"]);
        formData.value["business_mobile"] = "";
      }
    };

    const onLandLineEnter = () => {
      debugger;
      if (
        /\d{5}([- ]*)\d{6}/.test(formData.value["business_landline"]) &&
        !formData.value["landline_list"].includes(
          formData.value["business_landline"]
        )
      ) {
        formData.value["landline_list"].push(
          formData.value["business_landline"]
        );
        formData.value["business_landline"] = "";
      }
    };

    const onEmailEnter = () => {
      debugger;
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.value["business_emails"]
        ) &&
        !formData.value["email_list"].includes(
          formData.value["business_emails"]
        )
      ) {
        formData.value["email_list"].push(formData.value["business_emails"]);
        formData.value["business_emails"] = "";
      }
    };

    const designation = ref([]);
    const setDesignationdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            designation.value = body.contact_designation_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const department = ref([]);
    const setDepartmentdata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            department.value = body.department_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

   
     var formData1 = ref({})

    onMounted(async () => {
      //const db_data = {}
      await setFormData(props.data);
      await setTitledata();
      await setBranchTitledata();
      await setDesignationdata();
      await setDepartmentdata();
      
    });

    const rules = ref({
      individual_name_of_person: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      individual_designation_select: [
        {
          required: true,
          message: "Profession is required",
          trigger: "change",
        },
      ],
    });

    

    const branch_title = ref([]);
    const setBranchTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_DP)
          .then(({ body }) => {
            branch_title.value = body.branch_title_types;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };


    const title = ref([]);
    const setTitledata = async () => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA)
          .then(({ data }) => {
            title.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const city_value = ref()
    const pin_value = ref()
    const state_value = ref()
    const pan_data = ref()
    const desgination_value = ref()
    const setFormData = async (data) => {    
      var j = 0;
      
      try {
        var values = { "contact_id": data.ids, "page": 1, "records_per_page": 1, "search_term": "", }
        await store.dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST, values).then(({ data }) => {  
          city_value.value = data.city_id
          pin_value.value = data.pincode_id
          state_value.value = data.state_id
          pan_data.value = data.pan_verified_yn
          desgination_value.value = data.contact_designation_id
          //country_value.value = data.country_id
          console.log('props.data')
          console.log(data.contact_dob)
          console.log(data.contact_anniversary_date)
          console.log(data.contact_spouse_dob)

          var dob_data , annidob_data , spodob_data
            if(data.contact_dob == '0000-00-00'){
               formData1.value['individual_dob'] = ''
            }
            if(data.contact_dob !== '0000-00-00'){
              dob_data = data.contact_dob
            }

             if(data.contact_anniversary_date == '0000-00-00'){
               formData1.value['individual_anniversary_dob'] = ''
            }
            if(data.contact_anniversary_date !== '0000-00-00'){
              annidob_data = data.contact_anniversary_date
            }

             if(data.contact_spouse_dob == '0000-00-00'){
               formData1.value['individual_spouse_dob'] = ''
            }
            if(data.contact_spouse_dob !== '0000-00-00'){
              spodob_data = data.contact_spouse_dob
            }
            

          formData1.value = {
            individual_name_of_person: data.contact_name,
            id:  data.contact_id,
            company_select: data.company_id,
            branch_select : data.company_branch_id,
            individual_designation_select: data.contact_designation_id,
            individual_email_select: data.contact_email,
            individual_mobile_select: data.contact_mobile_no,
            individual_landline_select: data.contact_landline_no,
            individual_department_select: data.contact_department_id,
            individual_anniversary_dob: annidob_data == '' ? "0000-00-00" :  annidob_data,
            individual_dob: dob_data == '' ? "0000-00-00" :  dob_data,
            individual_spouse_dob: spodob_data == '' ? "0000-00-00" :  spodob_data,
            individual_pan: data.contact_pan,
            pan_verified : pan_data.value,
            active: data.active,
            };
             console.log('formData1.value')
            console.log(formData1.value)
        })
        
        .catch(({ response }) => {
          console.log(response);
        });
      } catch (e) {
        console.log(e);
      } 
    }
    
    const panverify = ref();
    async function setContactData(data) {
      console.log("AAAAAAAAAAAAADAT")
      console.log(data.pan_verified)
      var ind_dtt = "";
      var ind_anni_dtt = "";
      var ind_spouse_dtt = "";
      var user = JSON.parse(JwtService.getToken());
      try {
          if(data.individual_dob !== ""){
            ind_dtt = data.individual_dob
          }   
          
          if(data.individual_anniversary_dob != ""){
            ind_anni_dtt = data.individual_anniversary_dob
          }   

          if(data.individual_spouse_dob != ""){
            ind_spouse_dtt = data.individual_spouse_dob
          }   
        }
        catch{
          console.log('catch');
        }

        if(ind_dtt ){
          console.log('aa');
          console.log(ind_dtt); 
          
        }
        else if(data.individual_dob == undefined){
         // console.log("indi dob undefined")
          ind_dtt = ""
        }
        
        else{

          var dt = ("0" + data.individual_dob.getDate()).slice(-2)
          var mo = ("0" + (data.individual_dob.getMonth() + 1)).slice(-2)
          var yy = data.individual_dob.getFullYear()
          ind_dtt = (yy+'-'+mo+'-'+dt);
        };

        if(ind_anni_dtt){
          console.log('bb');
          console.log(ind_anni_dtt);
          
        }
        else if(data.individual_anniversary_dob === undefined){
          //console.log("indi anni dob undefined")
          
          ind_anni_dtt = ""

        }
        
        else{

          var dt_ani = ("0" + data.individual_anniversary_dob.getDate()).slice(-2)
          var mo_ani = ("0" + (data.individual_anniversary_dob.getMonth() + 1)).slice(-2)
          var yy_ani = data.individual_anniversary_dob.getFullYear()
          ind_anni_dtt = (yy_ani+'-'+mo_ani+'-'+dt_ani);
        };

        if(ind_spouse_dtt){
          console.log('cc');
          
        }
        else if(data.individual_spouse_dob === undefined){
          //console.log("indi spouse dob undefined")
          ind_spouse_dtt = ""
        }
        
        else{

          var dt_spouse = ("0" + data.individual_spouse_dob.getDate()).slice(-2)
          var mo_spouse = ("0" + (data.individual_spouse_dob.getMonth() + 1)).slice(-2)
          var yy_spouse = data.individual_spouse_dob.getFullYear()
          ind_spouse_dtt = (yy_spouse+'-'+mo_spouse+'-'+dt_spouse);
        };
       
      // if(data.pan_search !== ''){
      //   panverify.value = 1
      //   console.log("PAN SEARCH")
      // }
      // else{
      //   panverify.value = 0
      // }

      if(formData1.value['pan_search'] === undefined){
        if(data.pan_verified == 1){
          panverify.value = 1
        }
        else{
          panverify.value = 0
        }
      }
      else{
        panverify.value = 1
      }
      
      
      const db_data = {
        contact_id: data.id,
        contact_name: data.individual_name_of_person,
        designation_id: data.individual_designation_select || 0,
        department_id: data.individual_department_select,
        email: data.individual_email_select,
        mobile_no: data.individual_mobile_select,
        landline_no: data.individual_landline_select,
        contact_dob: (ind_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_dob'])).format("YYYY-MM-DD"))),
        contact_anniversary_date: (ind_anni_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_anniversary_dob'])).format("YYYY-MM-DD"))),
        contact_spouse_dob: (ind_spouse_dtt == "" ? "0000-00-00" : (moment(String(formData1.value['individual_spouse_dob'])).format("YYYY-MM-DD"))),
        contact_pan: data.individual_pan,
        pan_verified_yn: panverify.value,
        registration_no : "",
        // address_line_1: data.individual_address_line_1,
        // address_line_2: data.individual_address_line_2,
        // pincode_id: Number(data.pincode_id) || 0,
        user_id: user.user_id,
      };
      console.log("DB ADAT")
      console.log(db_data)
      await store
        .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_UPDATE, db_data)
        .then(({ is_error , message, status }) => {
          if (is_error == false){
            setTimeout(() => {
              loading.value = false;
              hideModal(addContactModalRef.value);
              Swal.fire({
                text: "Individual has been successfully updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                
                router.go(0);     
              });
            }, 2000);
          }
          if(is_error == true) {
            loading.value = false;

            Swal.fire({
              text: message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setContactData(formData1.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData1,
      rules,
      submit,
      formRef,
      loading,
      loadingPin,
      branch_title,
      designation,
      department,
      setBranchTitledata,
      setDesignationdata,
      setDepartmentdata,
      user_gst_type_data,
      addContactModalRef,
      freq_list_data,
      type_list_data,
      setTitledata,
      onMobileEnter,
      onEmailEnter,
      onLandLineEnter,
      title,
      getPanData,
      loadingPan,
      
    };
  },
});
