
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import PaymentRecords from "@/components/customers/cards/overview/PaymentRecords.vue";
import PaymentMethods from "@/components/customers/cards/overview/PaymentMethods.vue";
import CreditBalance from "@/components/customers/cards/overview/CreditBalance.vue";
import Invoices from "@/components/customers/cards/overview/Invoices.vue";

import UserProfileBranchList from "@/views/apps/userProfile/UserProfileBranchList.vue";
import UserProfileContactList from "@/views/apps/userProfile/UserProfileContactList.vue";
import UserProfilePlantList from "@/views/apps/userProfile/UserProfilePlantList.vue";
import UserProfileKmpList from "@/views/apps/userProfile/UserProfileKmpList.vue";
import UserProfileBankList from "@/views/apps/userProfile/UserProfileBankList.vue";
import UserProfileBrandList from "@/views/apps/userProfile/UserProfileBrandList.vue";
import UserProfileIndividualDocument from "@/views/apps/userProfile/UserProfileIndividualDocument.vue";
import UserProfileAddressesList from "@/views/apps/userProfile/UserProfileAddressesList.vue";
import KmpsCompListing from "@/views/apps/kmps/KmpsCompListing.vue";

import Earnings from "@/components/customers/cards/statments/Earnings.vue";
import Statement from "@/components/customers/cards/statments/Statement.vue";
import axios from "axios";
import EditUserProfileIndivi from "@/components/modals/forms/EditUserProfileIndivi.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

// console.log(this.$route.query.test)

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "apps-profile-details",
  components: {
    // PaymentRecords,
    // PaymentMethods,
    // CreditBalance,
    // Invoices,
     EditUserProfileIndivi,
      // UserProfileBranchList,
      // UserProfileContactList,
      // UserProfilePlantList,
      // UserProfileKmpList,
      // UserProfileBankList,
      // UserProfileBrandList,
      UserProfileIndividualDocument,
      UserProfileAddressesList,
    // Earnings,
    // Statement,
   // Dropdown3,
    NewCardModal,
    
  },
  methods: {},
  setup(props) {
    var ids = ref(props.id);
    const store = useStore();
    const loadingData = ref<boolean>(true);

    interface ViewCompanies {
      contact_name;
      contact_designation_name;
      contact_mobile_no;
      contact_email;
      contact_spouse_dob;
      contact_anniversary_date,
      contact_dob;
      contact_landline_no;
      contact_pan;
      pan_verified_yn: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      
    }

    var resultsView = ref<ViewCompanies>();

    resultsView.value = {
      contact_name :"",
      contact_designation_name:"",
      contact_mobile_no:"",
      contact_email:"",
      contact_pan:"",
      contact_spouse_dob:"",
      contact_anniversary_date: "",
      contact_dob: "",
      contact_landline_no: "",
      pan_verified_yn: {
        label: "",
        color: "",
      },
      active: {
        label: "",
        color: "",
      },
      
    };

    const getUsers = async () => {
      try {
        var values = {
          contact_id: props.id,
          search_term: "",
          page: 1,
          records_per_page: 1,
        }
        await store
          .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST, values)
          .then(({ data }) => {
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            var j = 0;

            if (data.company_verified_yn) {
              status_label = "Verified";
              status_color = "success";
            } else {
              status_label = "Unverified";
              status_color = "danger";
            }

            if (data.pan_verified_yn) {
              status_label = "Verified";
              status_color = "success";
            } else {
              status_label = "Unverified";
              status_color = "danger";
            }

            if (data.active) {
              active_label = "Yes";
              active_color = "success";
            } else {
              active_label = "No";
              active_color = "danger";
            }

            resultsView.value = {
              contact_name: data.contact_name,
              contact_designation_name: data.contact_designation_name,
              contact_mobile_no: data.contact_mobile_no,
              contact_email: data.contact_email,
              contact_pan: data.contact_pan,
              contact_spouse_dob : data.contact_spouse_dob,
              contact_anniversary_date : data.contact_anniversary_date,
              contact_dob: data.contact_dob,
              contact_landline_no: data.contact_landline_no,
              pan_verified_yn: {
                label: status_label,
                color: status_color,
              },
              active: {
                label: active_label,
                color: active_color,
              },
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await getUsers();
      setCurrentPageBreadcrumbs("Entity Details", ["Entity Profile"]);
    });

    const uploadImage = async (e: Event) => {
      // console.log(e.target?.files[0]);
      // var filsse = document?.querySelector('input[type=file]');
      //  console.log((<HTMLInputElement>document.getElementById('comp_logo')))
      // const file = document.querySelector('input[type=file]')?.files[0];
      // console.log(file);
      // const image_value = (<HTMLInputElement>e.target).value;
      // const image_name = image_value.replace(/^.*[\\\/]/, "");
      // const image_format = /[.]/.exec(image_name)
      //   ? /[^.]+$/.exec(image_name)
      //   : undefined;
      // let image_file_encoded = "";
      // const final_encoded_name =  ids + "." + image_format;
      // console.log(final_encoded_name);
      // // let file = filsse?.files[0];
      // let reader = new FileReader();
      // reader.readAsDataURL(file);
      // console.log(reader);
      // reader.onloadend = () => {
      //   image_file_encoded = reader.result?.replace(
      //     /^data:image\/[a-z]+;base64,/,
      //     ""
      //   );
      //   this.update_image(final_encoded_name, image_file_encoded);
      //   this.setState({
      //     image_file_encoded: image_file_encoded,
      //     final_encoded_name: final_encoded_name,
      //     image_name: image_name,
      //   });
      // }
    };

    //     const update_image = async () => {

    //       const data = {
    //         path: "company_image",
    //         fileName: ""
    // ,        file64: ""
    //       };

    //       try {
    //         await axios.post(
    //           process.env.VUE_APP_CUSTOME_APP_API_URL+"company/list",
    //           data,
    //           {
    //             headers: {
    //               "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh"
    //             },
    //           }
    //         ).then(({ data }) => {

    //         })
    //         .catch(({ response }) => {

    //           console.log(response);

    //         });

    //         } catch (e) {
    //           console.log(e);
    //         }
    //     }

    const removeImage = () => {
      // profileDetails.value.avatar = "/media/avatars/blank.png";
    };

    return {
      resultsView,
      removeImage,
      uploadImage,
      ids,
    };
  },
});
