
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import  { user_gst_type} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import {  address_title_types } from "@/core/data/genericData";

export default defineComponent({
  name: "add-branch-modal",
  directives: { mask },
  props: {
    id: { required: true },
  },
  components: {},
  methods: {
    onChange() {
      if (this.formData.pincode == "") {
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
      }
    },
  }, 
  setup(props) {
    const user_gst_type_data = user_gst_type;
    const address_title_type = address_title_types;
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addPlantModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const router = useRouter();

const formData = ref({
      branch_title_select: 5,
      address_line_1: "",
      address_line_2: "",
      pincode: "",
      pincode_id: "",
      city: "",
      state: "",
      country: "",
      city_id: "",
      state_id: "",
      country_id: "",
      state_tin: "",
    });
    
   

    onMounted(async () => {
      //const db_data = {}
      
      //await setBranchTitledata();
    });

    const rules = ref({
      address_title_select: [
        {
          required: true,
          message: "Address Title is required",
          trigger: "change",
        },
      ],
      address_line_1: [
        {
          required: true,
          message: "Address Line 1 is required",
          trigger: "change",
        },
      ],
      pincode: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "change",
        },
      ],
    });


   const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };

      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;

            for (let i = 0; i < data.length; i++) {
              formData.value.city = data[i].city_name;
              formData.value.state = data[i].state_name;
              formData.value.country = data[i].country_name;
              formData.value.city_id = data[i].city_id;
              formData.value.state_id = data[i].state_id;
              formData.value.pincode_id = data[i].pincode_id;
              formData.value.country_id = data[i].country_id;

              loadingPin.value = false;
              console.log();
            }
          })

          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
      // else{

      //     formData.value.city = ""
      //     formData.value.state = ""
      //     formData.value.country = ""
      //     formData.value.city_id = ""
      //     formData.value.state_id = ""
      //     formData.value.pincode_id = ""
      //     formData.value.country_id = ""

      //     Swal.fire({
      //       text: "Sorry, It's Invalid Pincode!",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });

      //     loadingPin.value = false;

      //   }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value.city = pin_details.city_name;
      formData.value.state = pin_details.state_name;
      formData.value.country = pin_details.country_name;
      formData.value.city_id = pin_details.city_id;
      formData.value.state_id = pin_details.state_id;
      formData.value.pincode_id = pin_details.pincode_id;
      formData.value.country_id = pin_details.country_id;
    };

   
    async function setAddressData(data) {
      var user = JSON.parse(JwtService.getToken());

      const db_data = {
        "contact_address_id" : 0,
        "contact_id" : Number(props.id),
        "address_title_type_id": data.address_title_select,
        "address_line_1": data.address_line_1,
        "address_line_2": data.address_line_2,
        "pincode_id": data.pincode_id || 0,
         "user_id": user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_UPDATE_ADDRESSES, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Address has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPlantModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          return false;
        });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setAddressData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      pin,
      getPincodeData,
      ClickPincodeData,
      formRef,
      loading,
      loadingPin,
      address_title_type,
      //setBranchTitledata,
      user_gst_type_data,
      addPlantModalRef,
    
    };
  },
});
